/*
=============================================
DOCUMENT
=============================================
*/

html, body {
	height: 100%;
	font-family: 'Open Sans', sans-serif;
	background-color: #fff;
	background-image: url('/images/public_site/hexagonal_cube_pattern.png');
	background-attachment: fixed;
	color: #555;
}

body {
	-webkit-touch-callout: none;
	-webkit-user-select: none; /* Disable selection/copy in UIWebView */
}

/*
=============================================
STRUCTURAL
=============================================
*/

#header {
	background-color: #fff;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 75px;
	box-shadow: 0 0 10px rgba(0,0,0,0.2);
	z-index: 2;
}

.stripe {
	position: relative;
	height: 100vh;
}

.stripe>div {
	position: relative;
	top: 75px;
	height: calc(100% - 75px);
}

/*
=============================================
GLOBAL TAGS
=============================================
*/

h1 {
	font-size: 48px;
	font-weight: 300;
}

h2 {
	font-size: 36px;
	margin-bottom: 30px;
}

a {
	/* necessary to make safari mobile recognize anchor tags without hrefs as clickable */
	cursor: pointer;
}

/*
=============================================
GLOBAL STYLES
=============================================
*/

.stripe {
	background-attachment: fixed;
	background-size: cover;
	background-position: 0 75px;
	background-repeat: no-repeat;
}

.btn {
	height: 45px;
	border-radius: 0;
	border-width: 0;
	padding: 12px 25px;
	outline: none;
	box-shadow: none !important;
}

.btn-default, .btn-default:focus, .btn-default:active, .btn-default:hover, .btn-default:active:focus, .btn-default:active:hover {
	background-color: #fff;
}

.btn-primary, .btn-primary:focus, .btn-primary:active, .btn-primary:hover, .btn-primary:active:focus, .btn-primary:active:hover {
	background-color: #000;
}

.form-control, .input-group-addon, .btn {
	border-radius: 0px;
	border-color: #ddd;
}

input.form-control {
	box-shadow: none;
	-webkit-box-shadow: none;
	-webkit-appearance: none;
}

.note {
	color: #999;
}

.testimonial-block {
	background-color: rgba(255,255,255,0.5);
	border: 2px solid rgba(255,255,255,0.8);
	min-height: 300px;
	padding: 30px 15px;
	font-size: 1.5em;
	position: relative;
}

.testimonial-block .credit {
	position: absolute;
	left: 15px;
	bottom: 15px;
	font-size: 0.8em;
}

/*
=============================================
HEADER STYLES
=============================================
*/

#logo {
	margin-top: 20px;
}

#menu {
	margin-top: 15px;
	margin-left: 0;
}

#menu li {
	display: inline-block;
}

#menu .btn-default {
	padding: 12px 15px;
}

#menu .btn-default:hover {
	border-bottom: 1px solid #59f01d;
}

#menu .btn-primary {
	margin-left: 15px;
}

/*
=============================================
STRIPE STYLES
=============================================
*/

#stripe-cta {
	background-image: url('/images/public_site/runner.jpg');
	color: #111;
}

#stripe-cta .note {
	color: #555;
}

#stripe-modules {
	/*  */
}

#stripe-testimonials {
	background-image: url('/images/public_site/testimonials.jpg');
	color: #111;
}

#stripe-testimonials .note {
	color: #555;
}

#stripe-get-started {
	/*  */
}

.price-plan-container
{

	margin: 0 5px 10px !important;
	padding: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	border-width: 1px;
	border-color: #d3e2f1;
	background-color: #f6fafb;
	width: 260px;
	height: 400px;
	-webkit-box-shadow: 10px 10px 5px -4px rgba(0,0,0,0.35);
	-moz-box-shadow: 10px 10px 5px -4px rgba(0,0,0,0.35);
	box-shadow: 10px 10px 5px -4px rgba(0,0,0,0.35);

}

.price-plan-pricing
{
	font-size: 20px;
	color: #59f01d;
	font-weight: bold;
}